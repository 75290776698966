import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import StartLeaveRequestLink from '../components/StartLeaveRequestLink'
import PrivateRoute from '../components/PrivateRoute'

export default function Overview() {
  const data = useStaticQuery(graphql`
    query OverviewPage {
      site {
        siteMetadata {
          pathPrefix
        }
      }
    }
  `)
  const { pathPrefix } = data.site.siteMetadata

  return (
    <PrivateRoute>
      <Layout>
        <SEO title="Overview" />
        <div className="content-outline-998">
          <div id="twocol-content">
            <h1 className="header-underline color1">
              Welcome to the Leave Center
            </h1>
            <p>
              These pages are designed to provide information and documents
              necessary for you to successfully manage your Leave of
              Absence. If you require time off for three or more consecutive
              days due to any illness or pregnancy for you or your family
              members, you can use our online leave form to submit a request
              for leave.
            </p>
            <p>
              <br />
              Please read below&nbsp;for&nbsp;information regarding your
              rights and responsibilities as well as federal information as
              it pertains to  Leave of Absence. In addition to the Federal
              Leave of Absence and Paid Benefit provisions regulated by the
              US Department of Labor, many states have  added Leave of absence
              and paid benefit provisions.&nbsp; Click on the &quot;View Laws By State&quot;
              tab to check what applies to you in your state.{' '}
            </p>
            <br />
            <h2 className="color3"> Federal Leave</h2>
            <ul>
              <li>
                <h5>
                  <a
                    href={`${pathPrefix}/Documents/FMLA Family and Medical Leave Act (FMLA) Employee Guide.pdf`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Family and Medical Leave Act Employee Guide
                  </a>
                </h5>
              </li>
              <li>
                <h5>
                  <a
                    href={`${pathPrefix}/Documents/FMLA Frequently Asked Questions.pdf`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    FMLA Frequently Asked Questions
                  </a>&nbsp;
                </h5>
              </li>
              <li>
                <h5>
                  <a
                    href={`${pathPrefix}/Documents/FMLA Military Leave Frequently Asked Questions.pdf`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Military Leave Provisions{' '}
                  </a>
                </h5>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              More Information from the US Department of Labor regarding
              FMLA Visit:<br />
              <a
                href="https://www.dol.gov/whd/fmla/fmla-faqs.htm"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.dol.gov/whd/fmla/fmla-faqs.htm
              </a>
            </p>
            <p>&nbsp;</p>
            <div id="button" style={{ float: 'right', fontSize: '1.25em' }}>
              <StartLeaveRequestLink>
                Submit A Leave Request »
              </StartLeaveRequestLink>
            </div>
            <div id="button" style={{ float: 'right', fontSize: '1.25em' }}>
              <StartLeaveRequestLink isCovid>
                Submit A Covid Leave »
              </StartLeaveRequestLink>
            </div>
            <p>&nbsp;</p>
          </div>
          <div id="twocol-pic">
            <img src={`${pathPrefix}/styles/images/pic-leaveoverview_3.jpg`} alt="leave overview" width="100%" />
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}
